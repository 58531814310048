import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import {Box, TextField, Divider } from '@mui/material';
import { FormActions } from '../molecules/FormActions';

export type TCategoryForm = {
  name: string;
};

const ProfileFormSchema = yup.object().shape({
  name: yup.string().required(),
});

interface Props {
  onSubmit: (p: TCategoryForm) => void;
  onDelete?: () => void;
  category?: TCategoryForm;
  title?: string;
}

export const CategoryForm = ({
  category,
  onDelete,
  onSubmit,
  title = "Category",
}: Props) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<TCategoryForm>({
    resolver: yupResolver(ProfileFormSchema),
    defaultValues: category
      ? {
          name: category.name,
        }
      : {},
  });

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} autoComplete='off' noValidate display='flex' flexDirection='column' alignItems='stretch'>
      <FormActions title={title} onDelete={onDelete}/>
      <Divider variant="fullWidth"/>
      <Box py={4} display='flex' flexDirection='column' alignItems='stretch'>
        <TextField {...register('name')} error={!!errors.name} label="Name"></TextField>
      </Box>
    </Box>
  );
};
