import { Box, Skeleton } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { QueryKey } from "../../../framework/constants/QueryKey";
import { SurveysService } from "../../../framework/services/api/SurveysService";
import { SurveyView } from "../../organisms/SurveyView";

export const ViewSurveyPage = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  if (!id) navigate({pathname: '/admin/surveys'});
  
  const {data, isLoading, isError, isRefetching} = useQuery([QueryKey.SURVEY_RESPONSE], () => SurveysService.GetOne(id!));

  if (isLoading || isRefetching) return (
    <Box>
      <Skeleton/>
    </Box>
  );
  if (isError || !data) return (
    <Box>
      Error!
    </Box>
  );

  return (
    <>
      <SurveyView survey={data}/>
    </>
  );
};
