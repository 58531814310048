import { Container } from '@mui/material';
import { useEffect } from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import { useAuthStore } from '../../framework/stores/AuthStore';

export const BaseLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {loggedIn, initialized, initialize} = useAuthStore(({loggedIn, initialized, initialize}) => ({loggedIn, initialized, initialize}));
  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (initialized && !loggedIn) navigate('/login');
    if (initialized && loggedIn && !location.pathname.includes('/admin')) navigate('/admin');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, loggedIn])

  return (
    <Container maxWidth="lg">
      <Outlet />
    </Container>
  );
}
