import { Box, Skeleton } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { QueryKey } from '../../../framework/constants/QueryKey';
import { useConfirmationDialog } from '../../../framework/hooks/useConfirmationDialog';
import { TemplatesService } from '../../../framework/services/api/TemplatesService';
import { TemplateForm, TTemplateForm } from '../../organisms/TemplateForm';


export const EditTemplatePage = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {data, isLoading, isError, isRefetching} = useQuery([QueryKey.TEMPLATE], () => TemplatesService.GetOne(id!))
  const {isLoading: mutationIsLoading, isError: mutationIsError, mutate} = useMutation(
    (formData: TTemplateForm) => TemplatesService.Update(data!.tid, data!.sid, formData),
    {onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.TEMPLATE])
      queryClient.invalidateQueries([QueryKey.TEMPLATES])
    }}
  );
  const mutationDelete = useMutation(
    () => TemplatesService.Delete(data!.tid),
    {onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.TEMPLATE])
      queryClient.invalidateQueries([QueryKey.TEMPLATES])
      navigate({pathname: '/admin/templates'})
    }}
  );
  const {open, DialogElement} = useConfirmationDialog(() => mutationDelete.mutate())
  if (isLoading || mutationIsLoading || isRefetching) return (
    <Box>
      <Skeleton/>
    </Box>
  );
  if (isError || mutationIsError) return (
    <Box>
      Error!
    </Box>
  );
  return (
    <>
      {DialogElement}
      <TemplateForm onSubmit={(data) => mutate(data)} template={data.template} title="Edit template" onDelete={open}/>
      {isError && <Box>An error occurred</Box>}
    </>
  );
};
