import { Box, FormControlLabel, Checkbox, Divider, Typography, useTheme } from '@mui/material';
import { PropsWithChildren, useRef } from 'react';
import { SurveyResponse } from '../../framework/models/server/SurveyResponse';
import { TSTypeGuard } from '../../framework/models/server/TemplateSchemaSection';
import { TagComponent } from '../atoms/TagComponent';

interface Props {
  survey: SurveyResponse
}

export const SurveyView = ({survey}: PropsWithChildren<Props>) => {
  const theme = useTheme();
  const qCount = useRef(0);

  return (
    <>
      <Box>
        <h1>Respondent Information</h1>
        <Typography variant="body1">Name: {survey.respondentName}</Typography>
        <Typography variant="body1">E-mail: {survey.respondentEmail}</Typography>
      </Box>
      {survey.sections.map((f, idx) => {
        if(TSTypeGuard.isQuestion(f)) {
          qCount.current += 1;
        }
        else {
          qCount.current = 0;
        }
        // helper with buttons to MOVE or REMOVE
        const SectionWrapper = ({children}: PropsWithChildren) => (
          <Box my={2} display="flex" flexDirection="column" alignItems="stretch">
            {children}
            {idx < survey.sections.length - 1 && <Divider sx={{marginTop: 2}} variant="fullWidth"/>}
          </Box>
        );
        // HEADINGS
        if (TSTypeGuard.isH1(f) || TSTypeGuard.isH2(f) || TSTypeGuard.isH3(f)) {
          return (
            <SectionWrapper key={idx}>
              <TagComponent tag={f.type}>{f.value}</TagComponent>
            </SectionWrapper>
          );
        }
        // PARAGRAPH
        if (TSTypeGuard.isP(f)) {
          return (
            <SectionWrapper key={idx}>
              <TagComponent tag={'p'}>{f.value}</TagComponent>
            </SectionWrapper>
          );
        }
        // QUESTIONS
        const QuestionLabel = TSTypeGuard.isQuestion(f) ? (
          <p style={{fontWeight: 700}}>{qCount.current}. {f.question} {f.required ? '*' : ''}</p>
        ) : null;
        const CommonQuestionControls = TSTypeGuard.isQuestion(f)
          ? (<Box>
            {
              TSTypeGuard.isSurveyQuestion(f) && f.userInput.comment ? (<>
                <p style={{fontWeight: 700}}>Kommentar:</p>
                <Box component={TagComponent} tag={'p'} className={'preformatted'}>
                  {f.userInput.comment}
                </Box>
              </>) :
              null
            }
          </Box>)
          : null
        // QUESTION - TEXT
        if (TSTypeGuard.isSurveyQuestionText(f)) {
          return (
            <SectionWrapper key={idx}>
              {QuestionLabel}
              <TagComponent tag={'p'} className={'preformatted'}>{f.userInput.answer}</TagComponent>
              {CommonQuestionControls}
            </SectionWrapper>
          );
        }
        // QUESTION MULTI-SELECT
        if (TSTypeGuard.isSurveyQuestionMulti(f)) {
          return (
            <SectionWrapper key={idx}>
              {QuestionLabel}
              <>
                {f.options.map((ogOpt, ogOptIdx) => {
                  const isSelected = !!f.userInput?.options?.find(userOpt => userOpt.label === ogOpt.label);
                  return (
                    <FormControlLabel
                      key={`${ogOpt.label}-${ogOptIdx}`}
                      label={
                        <Typography sx={{
                            fontWeight: ogOpt.correct ? 700 : undefined,
                            color: ogOpt.correct ? theme.palette.success.main : 'inherit'
                          }}>
                          {ogOpt.label}
                        </Typography>
                      }
                      sx={{marginBottom: 1}}
                      control={
                        <Checkbox
                          checked={isSelected}
                          readOnly
                        />
                      }
                    />
                  )
                })}
              </>
              {CommonQuestionControls}
            </SectionWrapper>
          );
        }
        return null;
      })}
    </>
  );
};
