import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface Props {
  open: boolean;
  onYes?: () => void;
  onNo?: () => void;
}
export default function ConfirmationDialog({open, onYes, onNo}: Props) {
  return (
    <Dialog open={open} onClose={onNo}>
      <DialogTitle>Confirm Action</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onNo}>No</Button>
        <Button onClick={onYes}>Yes</Button>
      </DialogActions>
    </Dialog>

  );
}
