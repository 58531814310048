import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useFieldArray, useForm, Controller } from 'react-hook-form';
import {Box, TextField, Divider, Typography, Button, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { FormActions } from '../molecules/FormActions';
import { TSAllSubtypes, TSFactory, TSTypeGuard } from '../../framework/models/server/TemplateSchemaSection';
import { CategoriesList } from './CategoriesList';
import { PropsWithChildren } from 'react';
import { NestedOptionsForm } from './NestedOptionsForm';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export type TTemplateForm = {
  name: string;
  category: string;
  password: string;
  categoryName: string;
  templateSchema: {
    sections: TSAllSubtypes[];
  }
};

const ProfileFormSchema = yup.object().shape({
  name: yup.string().required(),
  category: yup.string().required(),
  categoryName: yup.string().notRequired(),
  password: yup.string().required(),
});

interface Props {
  onSubmit: (p: TTemplateForm) => void;
  onDelete?: () => void;
  template?: TTemplateForm;
  title?: string;
}

export const TemplateForm = ({
  template,
  onDelete,
  onSubmit,
  title = "Template",
}: Props) => {
  const {
    handleSubmit,
    register,
    setValue,
    control,
    formState: { errors },
  } = useForm<TTemplateForm>({
    resolver: yupResolver(ProfileFormSchema),
    defaultValues: template
      ? {
          name: template.name,
          category: template.category,
          categoryName: template.categoryName,
          password: template.password,
          templateSchema: template.templateSchema
        }
      : {},
  });

  const {fields, move, append, remove } = useFieldArray({name: 'templateSchema.sections', control});

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} autoComplete='off' noValidate display='flex' flexDirection='column' alignItems='stretch'>
      <FormActions title={title} onDelete={onDelete}/>
      <Divider variant="fullWidth"/>
      <Box py={4} display='flex' flexDirection='column' alignItems='stretch'>
        <Typography sx={{marginY: 2}} variant="h5">Template information</Typography>
        <TextField sx={{marginBottom: 2}} {...register('name')} error={!!errors.name} label="Name" className=''></TextField>
        <TextField sx={{marginBottom: 2}} {...register('password')} error={!!errors.password} label="Password"></TextField>
        <TextField sx={{marginBottom: 2}} {...register('categoryName')} error={!!errors.category} disabled label="Category"></TextField>
        <Typography sx={{marginY: 1}} variant="body1">Pick a Category to classify this template:</Typography>
        <CategoriesList onRowClick={(id, name) => {setValue('category', id); setValue('categoryName', name)}}/>
        <Typography sx={{marginY: 2}} variant="h5">Template schema</Typography>
        {fields.map((f, idx) => {
          // helper with buttons to MOVE or REMOVE
          const SectionWrapper = ({children}: PropsWithChildren) => (
            <Box my={2} display="flex" flexDirection="column" alignItems="stretch">
              {children}
            </Box>
          );
          const SectionLabel = ({children}: PropsWithChildren) => (
            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography sx={{marginBottom: 1, flexGrow: 1}} variant="body1">{idx+1} - {children}</Typography>
              {idx > 0 && <IconButton color="primary" onClick={() => move(idx, idx-1)}><ArrowUpwardIcon /></IconButton>}
              {idx < (fields.length - 1) && <IconButton color="primary" onClick={() => move(idx, idx+1)}><ArrowDownwardIcon /></IconButton>}
              <IconButton color="error" onClick={() => remove(idx)}><DeleteForeverIcon /></IconButton>
            </Box>
          )
          // HEADINGS
          if (TSTypeGuard.isH1(f) || TSTypeGuard.isH2(f) || TSTypeGuard.isH3(f)) {
            return (
              <SectionWrapper key={idx}>
                <SectionLabel>Heading level {f.type.toUpperCase()}</SectionLabel>
                <TextField
                  sx={{marginBottom: 1}}
                  {...register(`templateSchema.sections.${idx}.value`)}
                  label="Heading text" />
              </SectionWrapper>
            );
          }
          // PARAGRAPH
          if (TSTypeGuard.isP(f)) {
            return (
              <SectionWrapper key={idx}>
                <SectionLabel>Paragraph of text</SectionLabel>
                <TextField
                  multiline
                  rows={5}
                  sx={{marginBottom: 1}}
                  {...register(`templateSchema.sections.${idx}.value`)}
                  label="Paragraph text" />
              </SectionWrapper>
            );
          }
          // QUESTIONS
          const CommonQuestionControls = TSTypeGuard.isQuestion(f)
            ? (<Box>
              <FormControlLabel sx={{marginBottom: 1}} control={
                <Controller
                  control={control}
                  name={`templateSchema.sections.${idx}.required`}
                  render={({field}) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                    />
                  )}
                  />
              } label="Required" />
              <FormControlLabel
                sx={{marginBottom: 1}}
                label="Allow additional comments"
                control={
                  <Controller
                    control={control}
                    name={`templateSchema.sections.${idx}.allowComment`}
                    render={({field}) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                      />
                    )}
                  />
                }/>
            </Box>)
            : null
          // QUESTION - TEXT
          if (TSTypeGuard.isQuestionText(f)) {
            return (
              <SectionWrapper key={idx}>
                <SectionLabel>Textual question</SectionLabel>
                <TextField
                  multiline
                  rows={3}
                  sx={{marginBottom: 1}}
                  {...register(`templateSchema.sections.${idx}.question`)}
                  label="Question" />
                {CommonQuestionControls}
              </SectionWrapper>
            );
          }
          // QUESTION MULTI-SELECT
          if (TSTypeGuard.isQuestionMulti(f)) {
            return (
              <SectionWrapper key={idx}>
                <SectionLabel>Question with provided answers</SectionLabel>
                <TextField
                  multiline
                  rows={3}
                  sx={{marginBottom: 1}}
                  {...register(`templateSchema.sections.${idx}.question`)}
                  label="Question" />
                <NestedOptionsForm {...{idx, control, register}}/>
                <FormControlLabel
                sx={{marginBottom: 1}}
                label="Allow multiple options to be selected"
                control={
                  <Controller
                    control={control}
                    name={`templateSchema.sections.${idx}.multiselect`}
                    render={({field}) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                      />
                    )}
                  />
                }/>
                {CommonQuestionControls}
              </SectionWrapper>
            );
          }
          return null;
        })}
        <Box py={2} display="flex" flexWrap='wrap'>
          <Button sx={{marginBottom: 1, marginRight: 1}} variant="contained" onClick={() => append(TSFactory.h1())}>Add Heading 1</Button>
          <Button sx={{marginBottom: 1, marginRight: 1}} variant="contained" onClick={() => append(TSFactory.h2())}>Add Heading 2</Button>
          <Button sx={{marginBottom: 1, marginRight: 1}} variant="contained" onClick={() => append(TSFactory.h3())}>Add Heading 3</Button>
          <Button sx={{marginBottom: 1, marginRight: 1}} variant="contained" onClick={() => append(TSFactory.paragraph())}>Add Paragraph</Button>
          <Button sx={{marginBottom: 1, marginRight: 1}} variant="contained" onClick={() => append(TSFactory.questionText())}>Add Textual Question</Button>
          <Button sx={{marginBottom: 1, marginRight: 1}} variant="contained" onClick={() => append(TSFactory.questionMultiYesNo())}>Add Yes/No Question</Button>
          <Button sx={{marginBottom: 1, marginRight: 1}} variant="contained" onClick={() => append(TSFactory.questionMulti())}>Add Multiselect Question</Button>
        </Box>
      </Box>
    </Box>
  );
};
