import { Category } from "../../models/server/Category";
import { Paginated, PaginationConfig } from "../../models/server/Paginated";
import { PBClient } from "../PocketbaseClient";

const sub = 'categories';

export const CategoriesService = {
  GetAll: async ({page, perPage}: PaginationConfig) => {
    return await PBClient.records.getList(sub, page+1, perPage) as unknown as Paginated<Category>;
  },
  GetOne: async (id: string) => {
    return await PBClient.records.getOne(sub, id) as unknown as Category;
  },
  Update: async (id: string, data: Partial<Category>) => {
    return await PBClient.records.update(sub, id, data) as unknown as Category;
  },
  Create: async (data: Partial<Category>) => {
    return await PBClient.records.create(sub, data) as unknown as Category;
  },
  Delete: async (id: string) => {
    return await PBClient.records.delete(sub, id) as unknown as Category;
  }
} as const;