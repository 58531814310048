import React, {PropsWithChildren, ReactElement} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import CategoryIcon from '@mui/icons-material/Category';
import SchemaIcon from '@mui/icons-material/Schema';
import PollIcon from '@mui/icons-material/Poll';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Outlet } from 'react-router-dom';
import { ListItemLink } from '../atoms/ListItemLink';
import { Button } from '@mui/material';
import { useAuthStore } from '../../framework/stores/AuthStore';

const drawerWidth = 240;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

export const NavLayout = (props: PropsWithChildren<Props>) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const authStore = useAuthStore();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuItems: {
    label: string;
    href: string;
    icon: ReactElement;
  }[] = [
    {
      label: 'Categories',
      href: '/admin/categories',
      icon: <CategoryIcon />,
    },
    {
      label: 'Templates',
      href: '/admin/templates',
      icon: <SchemaIcon />,
    },
    {
      label: 'Survey Results',
      href: '/admin/surveys',
      icon: <PollIcon />,
    },
  ];

  const drawer = (
    <div>
      <Toolbar>
        <Typography variant="body1" align="center">Miles-Solutions Surveys Manager</Typography>
      </Toolbar>
      <Divider />
      <List>
        {menuItems.map(({label, href, icon}, index) => (
          <ListItemLink key={label} icon={icon} to={href} primary={label}/>
        ))}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        className="no-print"
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography sx={{flexGrow: 1}} variant="body1" noWrap component="div">
            Welcome, {authStore.loggedInEmail}
          </Typography>
          <Button sx={{color: 'white'}} onClick={() => authStore.logout()}><LogoutIcon/> Logout</Button>
        </Toolbar>
      </AppBar>
      <Box
        className="no-print"
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          className="no-print"
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar className="no-print"/>
        <Outlet/>
      </Box>
    </Box>
  );
}
