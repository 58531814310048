import { Box, Button, TextField, Typography } from '@mui/material';
import { FormEvent, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../../framework/stores/AuthStore';

export const LoginPage = () => {
  const {loggedIn, login, initialize, initialized, loginError} = useAuthStore(({initialize, login, loggedIn, loginError, initialized}) => ({initialize, login, loggedIn, loginError, initialized}));
  const navigate = useNavigate();

  const emailRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const tryLogin = (e: FormEvent<HTMLDivElement>) => {
    e.preventDefault();
    const email = emailRef.current?.value || '';
    const password = passwordRef.current?.value || ''
    login({email, password})
  };
  
  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (initialized && loggedIn) navigate('/admin/categories');
  }, [initialized, loggedIn, navigate])

  return (
    <Box display="flex" flexDirection={'column'} justifyContent='center' alignItems='center' component={'form'} onSubmit={tryLogin} mt={16}>
      <Typography sx={{mb: 4}} variant='h3' align='center'>Miles-Solutions Surveys Backoffice</Typography>
      <TextField sx={{mb: 4}} label="Email" type="email" inputRef={emailRef}/>
      <TextField sx={{mb: 4}} label="Password" type="password" inputRef={passwordRef}/>
      <Button sx={{mb: 4}} type={'submit'}>Login</Button>

      {loginError && <Typography variant="body1" color={'red'}>Bad email and/or password.</Typography>}
    </Box>
  );
};
