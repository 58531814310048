import { Box, Skeleton } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { QueryKey } from '../../../framework/constants/QueryKey';
import { CategoriesService } from '../../../framework/services/api/CategoriesService';
import { CategoryForm, TCategoryForm } from '../../organisms/CategoryForm';


export const CreateCategoryPage = () => {
  const [category] = useState<TCategoryForm>({name: ''});
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {isLoading, isError, mutate} = useMutation(
    (formData: TCategoryForm) => CategoriesService.Create(formData),
    {onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKey.CATEGORY])
      queryClient.invalidateQueries([QueryKey.CATEGORIES])
      navigate(`../categories/${data.id}`)
    }}
  );
  if (isLoading ) return (
    <Box>
      <Skeleton/>
    </Box>
  );
  return (
    <>
      <CategoryForm onSubmit={mutate} category={category} title="Create new category"/>
      {isError && <Box>An error occurred</Box>}
    </>
  );
};
