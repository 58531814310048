import { PropsWithChildren } from 'react';

interface Props {
  tag: keyof JSX.IntrinsicElements;
  className?: string;
}

export const TagComponent = ({tag, className, children}: PropsWithChildren<Props>) => {
  const Tag = tag;
  return (
    <Tag className={className}>
      {children}
    </Tag>
  );
};