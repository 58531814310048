export type TSSectionType = 'h1' | 'h2' | 'h3' | 'paragraph' | 'question';
export type TSQuestionType = 'text' | 'multi';
export interface TSSection {
  type: TSSectionType;
}
/* Display elements */
export interface TSSectionH1 extends TSSection {
  type: 'h1';
  value: string;
}
export interface TSSectionH2 extends TSSection {
  type: 'h2';
  value: string;
}
export interface TSSectionH3 extends TSSection {
  type: 'h3';
  value: string;
}
export interface TSSectionParagraph extends TSSection {
  type: 'paragraph';
  value: string;
}
/* Questions */
export interface TSQuestion extends TSSection {
  type: 'question';
  questionType: TSQuestionType;
  question: string;
  allowComment: boolean;
  required: boolean;
}

export interface UserInputBase {
  comment?: string;
}
export interface TSSurveyQuestion extends TSQuestion {
  userInput: UserInputBase;
}

export interface TSQuestionText extends TSQuestion {}
export interface TSSurveyQuestionText extends TSQuestionText, TSSurveyQuestion {
  userInput: UserInputBase  & {
    answer?: string;
  }
}

export interface TSQuestionMultiOption {
  label: string;
  correct: boolean;
}
export interface TSQuestionMulti extends TSQuestion {
  options: TSQuestionMultiOption[]
  multiselect: boolean;
}
export interface TSSurveyQuestionMulti extends TSQuestionMulti, TSSurveyQuestion {
  userInput: UserInputBase  & {
    options?: TSQuestionMultiOption[];
  }
}

export type TSAllSubtypes = |
  TSSectionH1 |
  TSSectionH2 |
  TSSectionH3 |
  TSSectionParagraph |
  TSQuestion |
  TSQuestionText |
  TSQuestionMulti;

export type TSSurveyAllSubtypes = |
  TSSectionH1 |
  TSSectionH2 |
  TSSectionH3 |
  TSSectionParagraph |
  TSQuestion |
  TSSurveyQuestionText |
  TSSurveyQuestionMulti;

export const TSFactory = {
  h1: (): TSSectionH1 => ({type: 'h1', value: ''}),
  h2: (): TSSectionH2 => ({type: 'h2', value: ''}),
  h3: (): TSSectionH3 => ({type: 'h3', value: ''}),
  paragraph: (): TSSectionParagraph => ({type: 'paragraph', value: ''}),
  questionText: (): TSQuestionText => ({
    type: 'question',
    questionType: 'text',
    question: '',
    required: true,
    allowComment: false,
  }),
  questionMulti: (): TSQuestionMulti => ({
    type: 'question',
    questionType: 'multi',
    question: '',
    options: [],
    required: true,
    allowComment: true,
    multiselect: false,
  }),
  questionMultiYesNo: (): TSQuestionMulti => ({
    type: 'question',
    questionType: 'multi',
    question: '',
    options: [
      {correct: true, label: 'Ja'},
      {correct: false, label: 'Nein'},
    ],
    required: true,
    allowComment: true,
    multiselect: false,
  }),
  questionMultiOption: (): TSQuestionMultiOption => ({
    label: '',
    correct: false
  })
} as const;
export const TSTypeGuard = {
  // sections
   isH1: (x: TSSection): x is TSSectionH1 => x.type === 'h1',
   isH2: (x: TSSection): x is TSSectionH2 => x.type === 'h2',
   isH3: (x: TSSection): x is TSSectionH3 => x.type === 'h3',
   isP: (x: TSSection): x is TSSectionParagraph => x.type === 'paragraph',
   isQuestion: (x: TSSection): x is TSQuestion => x.type === 'question',
   isSurveyQuestion: (x: TSSection): x is TSSurveyQuestion => TSTypeGuard.isQuestion(x) && Object.hasOwn(x, 'userInput'),
   // questions
   isQuestionText: (x: TSSection): x is TSQuestionText => TSTypeGuard.isQuestion(x) && x.questionType === 'text',
   isSurveyQuestionText: (x: TSSection): x is TSSurveyQuestionText => TSTypeGuard.isSurveyQuestion(x) && x.questionType === 'text',
   isQuestionMulti: (x: TSSection): x is TSQuestionMulti => TSTypeGuard.isQuestion(x) && x.questionType === 'multi',
   isSurveyQuestionMulti: (x: TSSection): x is TSSurveyQuestionMulti => TSTypeGuard.isSurveyQuestion(x) && x.questionType === 'multi',

} as const;