import { PBClient } from '../services/PocketbaseClient';
import { StoreWithDevtools } from './StoreWithDevtools';

type LoginDTO = {
  email: string;
  password: string;
};

interface AuthState {
  initialized: boolean;
  loggedIn: boolean;
  loggedInEmail: string;
  loginError: boolean;
  initialize: () => Promise<void>;
  login: (data: LoginDTO) => Promise<void>;
  logout: () => Promise<void>;
}

export const useAuthStore = StoreWithDevtools<AuthState>((set, get) => ({
  initialized: false,
  loggedIn: false,
  loggedInEmail: '',
  loginError: false,
  initialize: async () => {
    set(() => ({loggedIn: false, loggedInEmail: ''}));
    if(PBClient.authStore.isValid) {
      set(() => ({loggedIn: true, loggedInEmail: PBClient.authStore.model?.email || '<unknown user>'}));
    }
    PBClient.authStore.onChange(() => {
      console.log('Registering a change in store!')
    })
    set(() => ({initialized: true}))
  },
  login: async (data: LoginDTO) => {
    set(() => ({loginError: false, loggedInEmail: ''}))
    try {
      const authData = await PBClient.admins.authViaEmail(data.email, data.password);
      if (authData) {
        set(() => ({loggedIn: true, loggedInEmail: PBClient.authStore.model?.email || '<unknown user>'}));
      }
    } catch (e) {
      set(() => ({loggedIn: false, loginError: true, loggedInEmail: ''}))
    }
  },
  logout: async () => {
    await PBClient.authStore.clear();
    set(() => ({loggedIn: false, loggedInEmail: ''}))
  },
}));
