import {useEffect} from 'react';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../framework/constants/QueryKey';
import { useTablePagination } from '../../framework/hooks/useTablePagination';
import { DataTableLayout } from '../molecules/DataTable';
import { FormatDate } from '../../framework/utils/FormatDate';
import { SurveysService } from '../../framework/services/api/SurveysService';

interface Props {
  onRowClick: (id: string) => void
}

export const SurveysList = ({onRowClick}: Props) => {
  const {page, perPage, tablePagination, setCount} = useTablePagination();
  const {data, isError, error, isLoading} = useQuery(
    [QueryKey.SURVEY_RESPONSE, page, perPage],
    () => SurveysService.GetAll({page, perPage}),
    {keepPreviousData: true}
  );
  useEffect(() => {
    if (!isLoading) setCount(data?.totalItems || 0)
  }, [data?.totalItems, isLoading, setCount])

  const rows = data?.items || [];
  if (isLoading) return (
    <Box>
      <Skeleton/>
    </Box>
  );
  if (isError) return (
    <Box> 
      <p>An error occurred: {error as string}</p>
    </Box>
  );

  const header = (
    <TableRow>
      <TableCell component="th">Respondent</TableCell>
      <TableCell component="th">Respondent email</TableCell>
      <TableCell component="th">Survey template</TableCell>
      <TableCell component="th">Received</TableCell>
    </TableRow>
  )
  
  return (
    <DataTableLayout footer={tablePagination} header={header}>
      {rows.map((row) => (
        <TableRow key={row.id} hover onClick={() => onRowClick(row.id)}>
          <TableCell scope="row">
            {row.respondentName}
          </TableCell>
          <TableCell scope="row">
            {row.respondentEmail}
          </TableCell>
          <TableCell style={{ width: 160 }}>
            {row.templateName || 'N/A'}
          </TableCell>
          <TableCell style={{ width: 160 }}>
            {FormatDate(row.created)}
          </TableCell>
        </TableRow>
      ))}
    </DataTableLayout>
  );
}
