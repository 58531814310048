import { Paginated, PaginationConfig } from "../../models/server/Paginated";
import { SurveyResponse } from "../../models/server/SurveyResponse";
import { PBClient } from "../PocketbaseClient";

const sub = 'surveyResponse';

export const SurveysService = {
  GetAll: async ({page, perPage}: PaginationConfig) => {
    return await PBClient.records.getList(sub, page+1, perPage, {sort: '-created'}) as unknown as Paginated<SurveyResponse>;
  },
  GetOne: async (id: string) => {
    return await PBClient.records.getOne(sub, id) as unknown as SurveyResponse;
  },
  Delete: async (id: string) => {
    return await PBClient.records.delete(sub, id) as unknown as SurveyResponse;
  }
} as const;