import { Box, Button, Checkbox, FormControlLabel, TextField, IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { PropsWithChildren } from 'react';
import {Control, Controller, useFieldArray, UseFormRegister} from 'react-hook-form';
import { TSFactory } from '../../framework/models/server/TemplateSchemaSection';

interface Props {
  idx: number
  control: Control<any, any>
  register: UseFormRegister<any>
}

export const NestedOptionsForm = ({idx, control, register}: PropsWithChildren<Props>) => {
  const {fields, remove, append} = useFieldArray({
    control,
    name: `templateSchema.sections[${idx}].options`
  })
  return (
    <>
      {fields.map((f,idx2) => (
        <Box key={idx2} sx={{marginBottom: 2}} display='flex' alignItems='center'>
          <TextField
            sx={{flexGrow: 1, marginRight: 2}}
            {...register(`templateSchema.sections.${idx}.options[${idx2}].label`)}
            label={`Option ${idx2+1}`} />
          <FormControlLabel
            label="Correct answer"
            control={
              <Controller
                control={control}
                name={`templateSchema.sections.${idx}.options[${idx2}].correct`}
                render={({field}) => (
                  <Checkbox
                    {...field}
                    checked={field.value}
                  />
                )}
              />
            }/>
            <IconButton color="error" onClick={() => remove(idx2)}><DeleteForeverIcon/></IconButton>
        </Box>
      ))}
      <Box sx={{marginBottom: 2}} display='flex' alignItems='center'>
        <Button variant="contained" onClick={() => append(TSFactory.questionMultiOption())}>Add Option</Button>
      </Box>
    </>
  );
};
