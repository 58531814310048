import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SurveysList } from '../../organisms/SurveysList';

export const SurveysPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box display='flex' pb={2}>
        <Typography variant="h4" flexGrow={1}>Collected Surveys</Typography>
      </Box>
      <SurveysList onRowClick={(id: string) => navigate({pathname: `${id}`})}/>
    </>
  );
};
