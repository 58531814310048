import React, {PropsWithChildren} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';

interface DataTableLayoutProps extends PropsWithChildren {
  header?: React.ReactElement
  footer?: React.ReactElement
}
export const DataTableLayout = ({children, footer, header}: DataTableLayoutProps) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }}>
        <TableHead>
          {header || null}
        </TableHead>
        <TableBody>
          {children}
        </TableBody>
        <TableFooter>
          <TableRow>
            {footer || null}
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
