import { useState } from "react";
import ConfirmationDialog from "../../components/molecules/ConfirmationDialog";

export function useConfirmationDialog (yesCb: () => void, noCb?: () => void) {
  const [open, setOpen] = useState(false);

  const DialogElement = <ConfirmationDialog
    open={open}
    onNo={() => {setOpen(false); noCb && noCb();}}
    onYes={() => {setOpen(false); yesCb(); }}
  />;

  return {
    open: () => setOpen(true),
    close: () => setOpen(false),
    DialogElement,
  }
}