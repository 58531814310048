import { TTemplateForm } from "../../../components/organisms/TemplateForm";
import { Paginated, PaginationConfig } from "../../models/server/Paginated";
import { Template, TemplateExpanded } from "../../models/server/Template";
import { TemplateSchema } from "../../models/server/TemplateSchema";
import { TSAllSubtypes } from "../../models/server/TemplateSchemaSection";
import { PBClient } from "../PocketbaseClient";
const subTemplates = 'templates';
const subSchemas = 'templateSchemas';
const expandCategory = {expand: 'category'};

export const TemplatesService = {
  GetAll: async ({page, perPage}: PaginationConfig) => {
    return await PBClient.records.getList(subTemplates, page+1, perPage,{...expandCategory, sort: '-created'}) as unknown as Paginated<TemplateExpanded>;
  },
  GetOne: async (tid: string) => {
    const template = await PBClient.records.getOne(subTemplates, tid, {...expandCategory}) as unknown as TemplateExpanded;
    const schemaList = await PBClient.records.getList(subSchemas, 1, 1, {filter: `template = "${template.id}"`}) as unknown as Paginated<TemplateSchema>;
    const schema = schemaList.items[0];
    if (!schema) throw new Error(`No schema was found for ${template.id}`);
    return {
      tid: template.id,
      sid: schema.id,
      template: TemplatesService.MapResponseToForm(template, schema)
    };
  },
  Update: async (tid: string, sid: string, data: TTemplateForm) => {
    const [tdata, sdata] = TemplatesService.ExtractFormToRequestBodies(data);
    const template = await PBClient.records.update(subTemplates, tid, {
      name: tdata.name,
      category: tdata.category,
    } as Partial<Template>,
    {...expandCategory}) as unknown as TemplateExpanded;
    const schema = await PBClient.records.update(subSchemas, sid, {
      password: sdata.password,
      sections: sdata.sections,
      template: sdata.template
    } as Partial<TemplateSchema>) as unknown as TemplateSchema;
    return {
      tid: template.id,
      sid: schema.id,
      template: TemplatesService.MapResponseToForm(template, schema)
    };
  },
  Create: async (data: TTemplateForm) => {
    const [tdata, sdata] = TemplatesService.ExtractFormToRequestBodies(data);
    const template = await PBClient.records.create(subTemplates, {
      name: tdata.name,
      category: tdata.category,
    } as Partial<Template>,
    {...expandCategory}) as unknown as TemplateExpanded;
    const schema = await PBClient.records.create(subSchemas, {
      password: sdata.password,
      sections: sdata.sections,
      template: template.id
    } as Partial<TemplateSchema>) as unknown as TemplateSchema;

    return {
      tid: template.id,
      sid: schema.id,
      template: TemplatesService.MapResponseToForm(template, schema)
    };
  },
  Delete: async (tid: string) => {
    await PBClient.records.delete(subTemplates, tid);
  },
  MapResponseToForm: (template: TemplateExpanded, schema: TemplateSchema): TTemplateForm => ({
    name: template.name,
    category: template["@expand"].category.id,
    categoryName: template["@expand"].category.name,
    password: schema.password,
    templateSchema: {
      sections: schema.sections as TSAllSubtypes[] // cast!
    }
  }),  
  ExtractFormToRequestBodies: (form: TTemplateForm): [Partial<Template>, Partial<TemplateSchema>] => ([
    {
      name: form.name,
      category: form.category
    },
    {
      password: form.password,
      sections: form.templateSchema.sections,
    }
  ])
} as const;