import { Box, Skeleton } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { QueryKey } from '../../../framework/constants/QueryKey';
import { TemplatesService } from '../../../framework/services/api/TemplatesService';
import { TemplateForm, TTemplateForm } from '../../organisms/TemplateForm';


export const CreateTemplatePage = () => {
  const [template] = useState<TTemplateForm>({
    name: '',
    category: '',
    categoryName: '',
    password: '',
    templateSchema: {
      sections: []
    }
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {isLoading, isError, mutate} = useMutation(
    (formData: TTemplateForm) => TemplatesService.Create(formData),
    {onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKey.TEMPLATE]);
      queryClient.invalidateQueries([QueryKey.TEMPLATES]);
      navigate(`../templates/${data.tid}`);
    }}
  );
  if (isLoading ) return (
    <Box>
      <Skeleton/>
    </Box>
  );
  return (
    <>
      <TemplateForm onSubmit={(data) => mutate(data)} template={template} title="Create new template"/>
      {isError && <Box>An error occurred</Box>}
    </>
  );
};
