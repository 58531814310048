import { Box, Typography, Button } from '@mui/material';
import React, { PropsWithChildren } from 'react';

interface Props {
  title?: string
  showReset?: boolean
  onDelete?: () => void
}

export const FormActions = ({onDelete, children, title, showReset = false}: PropsWithChildren<Props>) => {
  return (
    <Box display='flex' py={2}>
      <Typography variant='h5' flexGrow={1}>{title || 'Actions'}</Typography>
      <Button type="submit" color="primary">Save</Button>
      {showReset && <Button type="reset" color="secondary">Reset</Button>}
      {onDelete && <Button color='error' onClick={onDelete}>Delete</Button>}
      {children}
    </Box>
  );
};
