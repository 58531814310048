import { Box, Skeleton } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { QueryKey } from '../../../framework/constants/QueryKey';
import { TemplatesService } from '../../../framework/services/api/TemplatesService';
import { TemplateForm, TTemplateForm } from '../../organisms/TemplateForm';


export const CloneTemplatePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const cloneId = searchParams.get('id');

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  
  const {data, isFetching: isFetchingClone, refetch} = useQuery({
    queryKey: ['TEMP_STORAGE'],
    queryFn: () => TemplatesService.GetOne(cloneId!),
    enabled: false,
    onSettled: () => {
      setSearchParams(new URLSearchParams(), {replace: true});
    },
    refetchOnMount: true,
    cacheTime: 0,
  });
  if (!isFetchingClone && cloneId) { refetch(); }

  const {isLoading, isError, mutate} = useMutation(
    (formData: TTemplateForm) => TemplatesService.Create(formData),
    {onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKey.TEMPLATE]);
      queryClient.invalidateQueries([QueryKey.TEMPLATES]);
      navigate(`../templates/${data.tid}`);
    }}
  );

  if (!data?.template || isLoading) return (
    <Box>
      <Skeleton/>
    </Box>
  );
  return (
    <>
      <TemplateForm onSubmit={(data) => mutate(data)} template={data.template} title="Clone existing template"/>
      {isError && <Box>An error occurred</Box>}
    </>
  );
};
