import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LinkButton } from '../../atoms/LinkButton';
import { CategoriesList } from '../../organisms/CategoriesList';


export const CategoriesPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box display='flex' pb={2}>
        <Typography variant="h4" flexGrow={1}>Categories</Typography>
        <LinkButton to="new">Create New</LinkButton>
      </Box>
      <CategoriesList onRowClick={(id: string) => navigate({pathname: `${id}`})}/>
    </>
  );
};
