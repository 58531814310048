import { useEffect } from 'react';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../framework/constants/QueryKey';
import { useTablePagination } from '../../framework/hooks/useTablePagination';
import { DataTableLayout } from '../molecules/DataTable';
import { TemplatesService } from '../../framework/services/api/TemplatesService';
import { FormatDate } from '../../framework/utils/FormatDate';
import { LinkButton } from '../atoms/LinkButton';

interface Props {
  onRowClick: (id: string) => void
}

export const TemplatesList = ({onRowClick}: Props) => {
  const {page, perPage, tablePagination, setCount} = useTablePagination();
  const {data, isError, error, isLoading} = useQuery(
    [QueryKey.TEMPLATES, page, perPage],
    () => TemplatesService.GetAll({page, perPage}),
    {keepPreviousData: true}
  );
  useEffect(() => {
    if (!isLoading) setCount(data?.totalItems || 0)
  }, [data?.totalItems, isLoading, setCount])

  const rows = data?.items || [];
  if (isLoading) return (
    <Box>
      <Skeleton/>
    </Box>
  );
  if (isError) return (
    <Box> 
      <p>An error occurred: {error as string}</p>
    </Box>
  );

  const header = (
    <TableRow>
      <TableCell>Name</TableCell>
      <TableCell>Category</TableCell>
      <TableCell align="right">Created</TableCell>
      <TableCell align="right">Updated</TableCell>
    </TableRow>
  )
  
  return (
    <DataTableLayout footer={tablePagination} header={header}>
      {rows.map((row) => (
        <TableRow key={row.id} hover onClick={() => onRowClick(row.id)}>
          <TableCell component="th" scope="row">
            {row.name}
          </TableCell>
          <TableCell component="th" scope="row">
            {row['@expand'].category.name}
          </TableCell>
          <TableCell style={{ width: 160 }} align="right">
            {FormatDate(row.created)}
          </TableCell>
          <TableCell style={{ width: 160 }} align="right">
            {FormatDate(row.updated)}
          </TableCell>
          <TableCell align="right">
            <LinkButton to={`clone?id=${row.id}`}>Clone</LinkButton>
          </TableCell>
        </TableRow>
      ))}
    </DataTableLayout>
  );
}
