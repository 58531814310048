import React from "react";
import TablePagination from '@mui/material/TablePagination';
import { TablePaginationActions } from "../../components/molecules/TablePaginationActions";

export function useTablePagination() {
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(15);
  const [count, setCount] = React.useState(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const tablePagination = (
  <TablePagination
    rowsPerPageOptions={[5, 10, 15, 25, 100]}
    colSpan={10}
    count={count}
    rowsPerPage={perPage}
    page={page}
    SelectProps={{
      inputProps: {
        'aria-label': 'rows per page',
      },
      native: true,
    }}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
    ActionsComponent={TablePaginationActions}
  />)

  return {
    page,
    perPage,
    setCount,
    tablePagination
  }
}